/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, {  useState, useEffect, useCallback,useRef,useMemo } from 'react';
import { Dropdown, message } from "antd";
import rison from 'rison';
import { useSelector } from 'react-redux';
import { useQueryParams, BooleanParam } from 'use-query-params';
import { isEmpty } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  t,
  styled,
  css,
  SupersetTheme,
  SupersetClient,
  getExtensionsRegistry,
  useTheme,
} from '@superset-ui/core';
import { MainNav as Menu } from 'src/components/Menu';
import { Tooltip } from 'src/components/Tooltip';
import Icons from 'src/components/Icons';
import Label from 'src/components/Label';
import { findPermission } from 'src/utils/findPermission';
import { isUserAdmin } from 'src/dashboard/util/permissionUtils';
import {
  MenuObjectProps,
  UserWithPermissionsAndRoles,
  MenuObjectChildProps,
} from 'src/types/bootstrapTypes';
import { RootState } from 'src/dashboard/types';
import DatabaseModal from 'src/features/databases/DatabaseModal';
import { uploadUserPerms } from 'src/views/CRUD/utils';
import Vector from 'src/assets/images/userInfo/Vector.png';
import Shouquan from 'src/assets/images/userInfo/shouquan.png';
import Setting from 'src/assets/images/userInfo/setting.png';
import Quanxian from 'src/assets/images/userInfo/quanxian.png';
import Logout from 'src/assets/images/userInfo/logout.png';
import Photo from 'src/assets/images/userInfo/photo.png';
import Photo2 from 'src/assets/images/userInfo/photo2.png';
import AiHelperButton from 'src/pages/AiHelper/AiHelperButton';
import { MySubscriptLevel, levelMap } from 'src/pages/User/components/OrderList';
import { getMySubscript } from 'src/pages/User/components/OrderList/api';
import LanguagePicker from './LanguagePicker';
import {
  ExtensionConfigs,
  GlobalMenuDataOptions,
  RightMenuProps,
} from './types';
import api from '../../pages/User/Api/index';
import { isDemoAccount } from "../../utils/demoHelper";
import { isAdminGroup, mainUsersGroup } from './userGroup';
import styles from './index.module.less';

export interface MySubscriptProps {
  expired_at: string
  last_payment_at: string
  subscription_level: MySubscriptLevel
}

const extensionsRegistry = getExtensionsRegistry();

const StyledUserInfo = styled.div`
   position:absolute;
   right:10px;
   background: #fff;
   width:250px;
   border-radius: 10px;
   box-shadow: 3px 3px 10px #ccc;
   .sanjiao{
    position: absolute;
    top: 0;
    right: 0;
    height: 53px;
    width: 53px;
    background-color: #005AFF;
    border-radius: 0 10px 0 0;
    clip-path: polygon(0 0, 100% 100%,100% 0);
    }
    .sanjiao-text{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    font-size: 10px;
    color: #fff;
    transform: rotate(45deg);
    transform-origin: 0 50%;
     }
   .userPopover{
    width:100%;
    img {
      display: initial;
    }
    .popoverHeader{
      width:100%;
      display:flex;
      padding:20px;
      .headerImg{
        font-size:15px;color: #005AFF;width: 50px; height: 50px; border-radius: 50%; text-align: center; line-height: 50px; background: linear-gradient(121.16deg, #EDF4FF 14.48%, #EEECFF 81.57%);
        margin-right:20px;
      }
      .headerInfo{
        width:calc(100% - 70px);
        .headerInfoName{
          font-size:14px;
          font-weight: 400;
          color: #000000;
        }
        .headerInfoVersion{
          display: flex;
          jusitify-content: center;
          align-items: center;
        }
        .headerInfoFeeCharge {
          padding: 0px 10px;
          font-size:10px;
          height: 20px;
          line-height: 20px;
          color: #5D5D5D;
          text-align: center;
          margin:4px 0;
          border-radius: 3px;
        }
        .headerInfoAdvance {
          background: #00EEA2;
        }
        .headerInfoFree {
          background: #E0E7FF;
        }
        .headerInfoLifespan{
          color: #BCBECA;
          font-size:12px;
        }
      }
    }
    .poverList{
      padding: 0px 20px;
      height:50px;
      line-height: 50px;
      position: relative;
      img{
        margin-right:12px;
      }
      &:hover{
        background: #EEF4FF;
        cursor: pointer;
      }
    }
    .mask{
      &:hover:after{
        position: absolute;
        left: 60px;
        top: 0;
        background-color: #EEF4FF;
        content: '当前为Demo账号';
        z-index: 2;
        text-indent:-78px;
        right:0;
        bottom:0;
        cursor:not-allowed;
        text-align:center;
      }
    }
   }
`;

const versionInfoStyles = (theme: SupersetTheme) => css`
  padding: ${theme.gridUnit * 1.5}px ${theme.gridUnit * 4}px
    ${theme.gridUnit * 4}px ${theme.gridUnit * 7}px;
  color: ${theme.colors.grayscale.base};
  font-size: ${theme.typography.sizes.xs}px;
  white-space: nowrap;
`;
const StyledI = styled.div`
  color: ${({ theme }) => theme.colors.primary.dark1};
`;

const styledDisabled = (theme: SupersetTheme) => css`
  color: ${theme.colors.grayscale.light1};
  .ant-menu-item-active {
    color: ${theme.colors.grayscale.light1};
    cursor: default;
  }
`;

const StyledDiv = styled.div<{ align: string }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }) => align};
  align-items: center;
  margin-right: ${({ theme }) => theme.gridUnit}px;
  .ant-menu-submenu-title > svg {
    top: ${({ theme }) => theme.gridUnit * 5.25}px;
  }
  img {
    display: initial;
  }
`;

const StyledMenuItemWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledAnchor = styled.a`
  padding-right: ${({ theme }) => theme.gridUnit}px;
  padding-left: ${({ theme }) => theme.gridUnit}px;
`;

const tagStyles = (theme: SupersetTheme) => css`
  color: ${theme.colors.grayscale.light5};
`;

const styledChildMenu = (theme: SupersetTheme) => css`
  &:hover {
    color: ${theme.colors.primary.base} !important;
    cursor: pointer !important;
  }
`;

const { SubMenu } = Menu;

const RightMenu = ({
  align,
  settings,
  navbarRight,
  isFrontendRoute,
  environmentTag,
  setQuery,
}: RightMenuProps & {
  setQuery: ({
    databaseAdded,
    datasetAdded,
  }: {
    databaseAdded?: boolean;
    datasetAdded?: boolean;
  }) => void;
}) => {
  const user = useSelector<any, UserWithPermissionsAndRoles>(
    state => state.user,
  );
  const dashboardId = useSelector<RootState, number | undefined>(
    state => state.dashboardInfo?.id,
  );
  const userValues = user || {};
  const { roles } = userValues;
  const {
    CSV_EXTENSIONS,
    COLUMNAR_EXTENSIONS,
    EXCEL_EXTENSIONS,
    ALLOWED_EXTENSIONS,
    HAS_GSHEETS_INSTALLED,
  } = useSelector<any, ExtensionConfigs>(state => state.common.conf);
  const [showDatabaseModal, setShowDatabaseModal] = useState<boolean>(false);
  const [engine, setEngine] = useState<string>('');
  const canSql = findPermission('can_sqllab', 'Superset', roles);
  const canDashboard = findPermission('can_write', 'Dashboard', roles);
  const canChart = findPermission('can_write', 'Chart', roles);
  const canDatabase = findPermission('can_write', 'Database', roles);
  const canDataset = findPermission('can_write', 'Dataset', roles);
  const [userInfo, setUserInfo] = useState<any>({});
  const [isDemo, setIsDemo] = useState<boolean>(false);
  const userInfoRef = useRef<any>({});
  const { canUploadData, canUploadCSV, canUploadColumnar, canUploadExcel } =
    uploadUserPerms(
      roles,
      CSV_EXTENSIONS,
      COLUMNAR_EXTENSIONS,
      EXCEL_EXTENSIONS,
      ALLOWED_EXTENSIONS,
    );
  const [maskAuthFlag, maskSetAuthFlag] = useState<boolean>(false);
  const showActionDropdown = canSql || canChart || canDashboard;
  const [allowUploads, setAllowUploads] = useState<boolean>(false);
  const history = useHistory();
  const [nonExamplesDBConnected, setNonExamplesDBConnected] =
    useState<boolean>(false);
  const isAdmin = isUserAdmin(user);
  const showUploads = allowUploads || isAdmin;
  const dropdownItems: MenuObjectProps[] = [
    {
      label: t('Data'),
      icon: 'fa-database',
      childs: [
        {
          label: t('Connect database'),
          name: GlobalMenuDataOptions.DB_CONNECTION,
          perm: canDatabase && !nonExamplesDBConnected,
        },
        {
          label: t('Create dataset'),
          name: GlobalMenuDataOptions.DATASET_CREATION,
          url: '/dataset/add/',
          perm: canDataset && nonExamplesDBConnected,
        },
        {
          label: t('Connect Google Sheet'),
          name: GlobalMenuDataOptions.GOOGLE_SHEETS,
          perm: canDatabase && HAS_GSHEETS_INSTALLED,
        },
        {
          label: t('Upload CSV to database'),
          name: 'Upload a CSV',
          url: '/csvtodatabaseview/form',
          perm: canUploadCSV && showUploads,
          disable: isAdmin && !allowUploads,
        },
        {
          label: t('Upload columnar file to database'),
          name: 'Upload a Columnar file',
          url: '/columnartodatabaseview/form',
          perm: canUploadColumnar && showUploads,
          disable: isAdmin && !allowUploads,
        },
        {
          label: t('Upload Excel file to database'),
          name: 'Upload Excel',
          url: '/exceltodatabaseview/form',
          perm: canUploadExcel && showUploads,
          disable: isAdmin && !allowUploads,
        },
      ],
    },
    {
      label: t('SQL query'),
      url: '/sqllab?new=true',
      icon: 'fa-fw fa-search',
      perm: 'can_sqllab',
      view: 'Superset',
    },
    {
      label: t('Chart'),
      url: Number.isInteger(dashboardId)
        ? `/chart/add?dashboard_id=${dashboardId}`
        : '/chart/add',
      icon: 'fa-fw fa-bar-chart',
      perm: 'can_write',
      view: 'Chart',
    },
    {
      label: t('Dashboard'),
      url: '/dashboard/new',
      icon: 'fa-fw fa-dashboard',
      perm: 'can_write',
      view: 'Dashboard',
    },
  ];
const [openStatus,setOpenStatus]=useState(false)
  const handleOpenChange = (status: boolean) => {
    setOpenStatus(status);
}
  const checkAllowUploads = () => {
    const payload = {
      filters: [
        { col: 'allow_file_upload', opr: 'upload_is_enabled', value: true },
      ],
    };
    SupersetClient.get({
      endpoint: `/api/v1/database/?q=${rison.encode(payload)}`,
    }).then(({ json }: Record<string, any>) => {
      // There might be some existings Gsheets and Clickhouse DBs
      // with allow_file_upload set as True which is not possible from now on
      const allowedDatabasesWithFileUpload =
        json?.result?.filter(
          (database: any) => database?.engine_information?.supports_file_upload,
        ) || [];
      setAllowUploads(allowedDatabasesWithFileUpload?.length >= 1);
    });
  };

  const existsNonExamplesDatabases = () => {
    const payload = {
      filters: [{ col: 'database_name', opr: 'neq', value: 'examples' }],
    };
    SupersetClient.get({
      endpoint: `/api/v1/database/?q=${rison.encode(payload)}`,
    }).then(({ json }: Record<string, any>) => {
      setNonExamplesDBConnected(json.count >= 1);
    });
  };

  const [mySubscript, setMySubscript] = useState<MySubscriptProps>();
  
  useEffect(() => {
    getMySubscript().then(res => {
      setMySubscript(res.json.ret || {});
    })
  }, [])

  useEffect(() => {
    if (canUploadData) {
      checkAllowUploads();
    }
  }, [canUploadData]);

  useEffect(() => {
    if (canDatabase || canDataset) {
      existsNonExamplesDatabases();
    }
  }, [canDatabase, canDataset]);

  useEffect(()=>{
    setIsDemo(isDemoAccount() && !isAdminGroup());
  },[userInfo]);
  
  useEffect(() => {
    // if(isTempBlockAuth) return;
    if (['/home/', '/register/', '/login/', '/pricing/'].includes(window.location.pathname)) {
      // 首页、注册、登录页不需要检测授权 - 临时处理
      return;
    }
    SupersetClient.get({
      host: `${window.location.host}/extra_server/`,
      endpoint: `user/dataAuth`,
      mode: 'cors',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: null,
      parseMethod: 'json',
    }).then(data => {
      const { json = {} } = data;
      if (json.ret === "NoAuth" && !isAdminGroup()) {
        maskSetAuthFlag(true);
        
      }else {
        maskSetAuthFlag(false);
      }
    });
  }, [userInfo]);

  const menuIconAndLabel = (menu: MenuObjectProps) => (
    <>
      <i data-test={`menu-item-${menu.label}`} className={`fa ${menu.icon}`} />
      {menu.label}
    </>
  );

  const handleMenuSelection = (itemChose: any) => {
    if (itemChose.key === GlobalMenuDataOptions.DB_CONNECTION) {
      setShowDatabaseModal(true);
    } else if (itemChose.key === GlobalMenuDataOptions.GOOGLE_SHEETS) {
      setShowDatabaseModal(true);
      setEngine('Google Sheets');
    }
  };

  const isMainCountGroup = (userId: number) => mainUsersGroup.includes(userId)

  const toUserInfo = () => {
    setOpenStatus(false)
    // history.push('/user/info/1');
    console.log(window.location.origin);
    
    // window.location.assign(`${window.location.origin}/user/info/?id=1`)
    history.push('/user/info/?id=1')
    // history.push('/user/info?id=1')
  }
  const toLogin = () => {
    // history.push(navbarRight.user_logout_url);
    window.localStorage.removeItem('__isDemo_account__'); // 已授权，移除本地demo模式残留
    window.location.href=navbarRight.user_logout_url;
  }
  const handleToChild = async() => {
    if (userInfoRef.current && userInfoRef.current.userName.toLowerCase() === 'demo') return
    const res = await api.verifyChildAuth();
    console.log(res);
    if (!res.ret) {
      message.error('暂无权限')
      return
    }
    console.log(res);
    // history.push('/user/info/3');
    // window.location.assign(`${window.location.origin}/user/info/?id=3`)
    history.push('/user/info/?id=3')
  }
  const handleAuthorization = () => {
    setOpenStatus(false)
    window.location.href = '/auth/';
  };

  const handleFaceBookAuth = () => {
    setOpenStatus(false)
    // window.location.href = '/fbAuth';
    history.push('/fbAuth');
  }

  const handleChooseTaocan = () => {
    history.push('/pricing/');
  };

  const handleOnHideModal = () => {
    setEngine('');
    setShowDatabaseModal(false);
  };

  const tooltipText = t(
    "Enable 'Allow file uploads to database' in any database's settings",
  );

  const buildMenuItem = (item: MenuObjectChildProps) =>
    item.disable ? (
      <Menu.Item key={item.name} css={styledDisabled}>
        <Tooltip placement="top" title={tooltipText}>
          {item.label}
        </Tooltip>
      </Menu.Item>
    ) : (
      <Menu.Item key={item.name} css={styledChildMenu}>
        {item.url ? <a href={item.url}> {item.label} </a> : item.label}
      </Menu.Item>
    );

  const onMenuOpen = (openKeys: string[]) => {
    // We should query the API only if opening Data submenus
    // because the rest don't need this information. Not using
    // "Data" directly since we might change the label later on?
    if (
      openKeys.length > 1 &&
      !isEmpty(
        openKeys?.filter((key: string) =>
          key.includes(`sub2_${dropdownItems?.[0]?.label}`),
        ),
      )
    ) {
      if (canUploadData) checkAllowUploads();
      if (canDatabase || canDataset) existsNonExamplesDatabases();
    }
    return null;
  };
  const RightMenuExtension = extensionsRegistry.get('navbar.right');
  const RightMenuItemIconExtension = extensionsRegistry.get(
    'navbar.right-menu.item.icon',
  );

  const handleDatabaseAdd = () => setQuery({ databaseAdded: true });

  const adminMenu = useMemo(() => (
    <>
      <div className="poverList" onClick={() => {
        setOpenStatus(false);
        history.push('/databaseview/list/');
      }}>
        <img src={Setting} alt="" />
        数据库管理
      </div>
      <div className="poverList" onClick={() => window.open('/users/list/')}>
        <img src={Setting} alt="" />
        用户管理
      </div>
      <div className="poverList" onClick={() => window.open('/roles/list/')}>
        <img src={Setting} alt="" />
        角色管理
      </div>
      <div className="poverList" onClick={() => window.open('/csstemplatemodelview/list/')}>
        <img src={Setting} alt="" />
        CSS模板
      </div>
      <div className="poverList" onClick={() => window.open('/annotationlayer/list/')}>
        <img src={Setting} alt="" />
        注释层
      </div>
    </>
  ), []);

  const theme = useTheme();
  const getUser = async () => {
    try {
      const res = await api.getUserInfo();
      // let img = await api.getImg(res.imageResource)
      if (res) {
        const userObj = { ...JSON.parse(JSON.stringify(res)), imageUrl: `data:image/png;base64,${  res.imageResource}` };
        userInfoRef.current = userObj;
        if (isMainCountGroup(userObj.mainAccountId)) {
          window.sessionStorage.setItem('__main__gropu__', 'true');
        } else {
          window.sessionStorage.removeItem('__main__gropu__');
        }
        setUserInfo(userObj);
        if (isMainCountGroup(userObj.mainAccountId)) {
          window.sessionStorage.setItem('__main__gropu__', 'true');
        } else {
          window.sessionStorage.removeItem('__main__gropu__');
        }
      }
    } catch (error) {
      console.error("获取用户信息异常", error);
    }
  }
  const feeChargeHandle = () => {
    history.push('/pricing/');
  }
  const handleSwitchDemoMode = () => {
    const isDemoMode = isDemoAccount();
    if (isDemoMode) {
      window.localStorage.removeItem('__isDemo_account__'); // 设置账户类型
    } else {
      setIsDemo(!isDemo);
      window.localStorage.setItem('__isDemo_account__', 'true'); // 设置账户类型
    }
    location.reload();
  }
  useEffect(() => {
    getUser()
  }, [openStatus])

  const headerInfoClass = () => {
    if (mySubscript?.subscription_level === 'ADVANCED') return 'headerInfoAdvance';
    if (mySubscript?.subscription_level === 'FREE') return 'headerInfoFree';
    return ''
  }

  const getPopupContainer = (HTMLElement: HTMLElement) => HTMLElement;
  const dropdownRender = useCallback(() =>
    <StyledUserInfo>
      <div className='userPopover'>
        <div className='popoverHeader'>
          <div className='headerImg'>
            {
              userInfoRef.current && userInfoRef.current.imageResource ? <img style={{ width: '100%', height: '100%', borderRadius: '50%' }} src={userInfoRef.current.imageUrl} />
                : userInfoRef.current.realName ? <div style={{ width: '100%', height: '100%', borderRadius: '50%', background: 'linear-gradient(121.16deg, #EDF4FF 14.48%, #EEECFF 81.57%)' }}>{userInfoRef.current.realName.slice(-2)}</div> : <img style={{ borderRadius: '50%', width: '100%', height: '100%' }} src={Photo2} alt="" />
            }
          </div>
          <div className='headerInfo'>
            <div className='headerInfoName'>{userInfoRef.current && userInfoRef.current.userName}</div>
            <div className='headerInfoVersion'>
              {
                mySubscript && <span className={`headerInfoFeeCharge ${headerInfoClass()}`}>
                  {levelMap[mySubscript.subscription_level]}
                </span>
              }
              {
                mySubscript?.subscription_level === 'ADVANCED' && <span className={styles.fee_charge} onClick={feeChargeHandle}>
                  续费
                </span>
              }
              {
                mySubscript?.subscription_level === 'FREE' && <span className={styles.fee_charge} onClick={feeChargeHandle}>
                  升级
                </span>
              }
            </div>
            <div className='headerInfoLifespan'>到期日：{mySubscript && dayjs(mySubscript.expired_at).format('YYYY-MM-DD')}</div>
          </div>
        </div>
        <div className={isDemo ? 'poverList mask' : 'poverList'} onClick={() => { !isDemo && toUserInfo() }}>
          <img src={Setting} alt="" />
          账户设置
        </div>
        {/* <div
          className={isDemo ? 'poverList mask' : 'poverList'}
          onClick={() => {
            setOpenStatus(false);
            history.push('/tablemodelview/list/');
          }}
        >
          <img src={Setting} alt="" />
          数据集管理
        </div> */}
        {userInfoRef.current && `${userInfoRef.current.accountType}` === '0' ?
          <>
            <div className={isDemo ? 'poverList mask' : 'poverList'} onClick={() => { !isDemo && handleToChild() }}>
              <img src={Quanxian} alt="" />
              子账号
            </div>
            <div className={isDemo ? 'poverList mask' : 'poverList'} onClick={() => { !isDemo && handleAuthorization() }}>
              <img src={Shouquan} alt="" />
              授权管理
            </div>
          </> :
          <></>
        }
        {/* <div className={isDemo ? 'poverList mask' : 'poverList'} onClick={isDemo ? undefined : handleFaceBookAuth}>
          <img src={Shouquan} alt="" />
          FaceBook授权
        </div> */}
        <div className={isDemo ? 'poverList mask' : 'poverList'} onClick={() => { !isDemo && handleChooseTaocan() }}>
          <img src={Shouquan} alt="" />
          套餐选购
        </div>
        {
          isAdmin && adminMenu // admin可访问菜单
        }
        <div className='poverList' onClick={toLogin}>
          <img src={Logout} alt="" />
          退出登录
        </div>
      </div>
      <div className="sanjiao" style={{ background: userInfoRef.current && `${userInfoRef.current.accountType}` === '0' ? '#005AFF' : '#BCBECA' }} />
      <div className="sanjiao-text">{userInfoRef.current && `${userInfoRef.current.accountType}` === '0' ? '主账号' : '子账号'}</div>
    </StyledUserInfo>
    ,
    [isAdmin, adminMenu, isDemo, maskAuthFlag, mySubscript])
  return (
    <StyledDiv align={align}>
      {canDatabase && (
        <DatabaseModal
          onHide={handleOnHideModal}
          show={showDatabaseModal}
          dbEngine={engine}
          onDatabaseAdd={handleDatabaseAdd}
        />
      )}
      {environmentTag?.text && (
        <Label
          css={{ borderRadius: `${theme.gridUnit * 125}px` }}
          color={
            /^#(?:[0-9a-f]{3}){1,2}$/i.test(environmentTag.color)
              ? environmentTag.color
              : environmentTag.color
                .split('.')
                .reduce((o, i) => o[i], theme.colors)
          }
        >
          <span css={tagStyles}>{environmentTag.text}</span>
        </Label>
      )}

      <AiHelperButton />

      <Menu
        selectable={false}
        mode="horizontal"
        onClick={handleMenuSelection}
        onOpenChange={onMenuOpen}
      >
        {RightMenuExtension && <RightMenuExtension />}
        {/* {!navbarRight.user_is_anonymous && showActionDropdown && (
          <SubMenu
            data-test="new-dropdown"
            title={
              <StyledI data-test="new-dropdown-icon" className="fa fa-plus" />
            }
            icon={<Icons.TriangleDown />}
          >
            {dropdownItems?.map?.(menu => {
              const canShowChild = menu.childs?.some(
                item => typeof item === 'object' && !!item.perm,
              );
              if (menu.childs) {
                if (canShowChild) {
                  return (
                    <SubMenu
                      key={`sub2_${menu.label}`}
                      className="data-menu"
                      title={menuIconAndLabel(menu)}
                    >
                      {menu?.childs?.map?.((item, idx) =>
                        typeof item !== 'string' && item.name && item.perm ? (
                          <Fragment key={item.name}>
                            {idx === 3 && <Menu.Divider />}
                            {buildMenuItem(item)}
                          </Fragment>
                        ) : null,
                      )}
                    </SubMenu>
                  );
                }
                if (!menu.url) {
                  return null;
                }
              }
              return (
                findPermission(
                  menu.perm as string,
                  menu.view as string,
                  roles,
                ) && (
                  <Menu.Item key={menu.label}>
                    {isFrontendRoute(menu.url) ? (
                      <Link to={menu.url || ''}>
                        <i
                          data-test={`menu-item-${menu.label}`}
                          className={`fa ${menu.icon}`}
                        />{' '}
                        {menu.label}
                      </Link>
                    ) : (
                      <a href={menu.url}>
                        <i
                          data-test={`menu-item-${menu.label}`}
                          className={`fa ${menu.icon}`}
                        />{' '}
                        {menu.label}
                      </a>
                    )}
                  </Menu.Item>
                )
              );
            })}
          </SubMenu>
        )} */}

        <div style={{ marginRight: '30px', display: 'flex' }}>
          {
            maskAuthFlag ? <span onClick={handleSwitchDemoMode}
              style={{
                width: '108px', height: '30px', lineHeight: '30px', textAlign: 'center', background: '#005AFF',
                color: '#fff', fontSize: '16px', cursor: 'pointer', borderRadius: '6px', margin: '9px 18px 0 0',
                backgroundImage: 'linear-gradient(308.57deg, #B2BFFF -1.05%, #F6F7FF -1.05%, #F6F7FF -1.05%, #F9B5FF -1.04%, #4967FF 84.91%)'
              }}>{
                isDemo ? '返回' : '体验DEMO'
              }</span> : ''
          }

          <Dropdown
            placement="bottomRight"
            getPopupContainer={getPopupContainer}
            visible={openStatus}
            overlayStyle={{ zIndex: 9999 }}
            onVisibleChange={handleOpenChange}
            overlay={dropdownRender || null}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ fontSize: '14px', color: '#005AFF', width: '40px', height: '40px', borderRadius: '50%', textAlign: 'center', lineHeight: '40px' }}>
                {
                  userInfo && userInfo.imageResource ? <img style={{ width: '100%', height: '100%', borderRadius: '50%' }} src={userInfo.imageUrl} />
                    : userInfo.realName ? <div style={{ width: '100%', height: '100%', borderRadius: '50%', background: 'linear-gradient(121.16deg, #EDF4FF 14.48%, #EEECFF 81.57%)' }}>{userInfo.realName.slice(-2)}</div> : <img style={{ borderRadius: '50%', width: '100%', height: '100%' }} src={Photo} alt="" />
                }
              </div>
              <div style={{ marginLeft: '10px' }}><img src={Vector} alt="" sizes="" /></div>
            </div>
          </Dropdown>
        </div>
        {navbarRight.show_language_picker && (
          <LanguagePicker
            locale={navbarRight.locale}
            languages={navbarRight.languages}
          />
        )}
      </Menu>
      {navbarRight.documentation_url && (
        <>
          <StyledAnchor
            href={navbarRight.documentation_url}
            target="_blank"
            rel="noreferrer"
            title={navbarRight.documentation_text || t('Documentation')}
          >
            {navbarRight.documentation_icon ? (
              <i className={navbarRight.documentation_icon} />
            ) : (
              <i className="fa fa-question" />
            )}
          </StyledAnchor>
          <span>&nbsp;</span>
        </>
      )}
      {navbarRight.bug_report_url && (
        <>
          <StyledAnchor
            href={navbarRight.bug_report_url}
            target="_blank"
            rel="noreferrer"
            title={navbarRight.bug_report_text || t('Report a bug')}
          >
            {navbarRight.bug_report_icon ? (
              <i className={navbarRight.bug_report_icon} />
            ) : (
              <i className="fa fa-bug" />
            )}
          </StyledAnchor>
          <span>&nbsp;</span>
        </>
      )}
      {navbarRight.user_is_anonymous && (
        <StyledAnchor href={navbarRight.user_login_url}>
          <i className="fa fa-fw fa-sign-in" />
          {t('Login')}
        </StyledAnchor>
      )}
    </StyledDiv>
  );
};

const RightMenuWithQueryWrapper: React.FC<RightMenuProps> = props => {
  const [, setQuery] = useQueryParams({
    databaseAdded: BooleanParam,
    datasetAdded: BooleanParam,
  });

  return <RightMenu setQuery={setQuery} {...props} />;
};

// Query param manipulation requires that, during the setup, the
// QueryParamProvider is present and configured.
// Superset still has multiple entry points, and not all of them have
// the same setup, and critically, not all of them have the QueryParamProvider.
// This wrapper ensures the RightMenu renders regardless of the provider being present.
class RightMenuErrorWrapper extends React.PureComponent<RightMenuProps> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  noop = () => {};

  render() {
    if (this.state.hasError) {
      return <RightMenu setQuery={this.noop} {...this.props} />;
    }

    return this.props.children;
  }
}

const RightMenuWrapper: React.FC<RightMenuProps> = props => (
  <RightMenuErrorWrapper {...props}>
    <RightMenuWithQueryWrapper {...props} />
  </RightMenuErrorWrapper>
);

export default RightMenuWrapper;
